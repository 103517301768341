/*
 * @Author: SongYijie
 * @Date: 2020-02-10 11:15:35
 * @LastEditors: SongYijie
*/
import axios from 'axios'
import store from '@/store'
import router from '@/router'

let fetchUrl = 'https://h5-api-test.xiyk.cn'
const { location } = window
if (location.origin.indexOf('https://m.xiydr.cn') > -1 || location.origin.indexOf('https://h5.xiyk.cn') > -1 || location.origin.indexOf('h5.kylincloud-saas') > -1 ) {
  fetchUrl = 'https://h5-api.xiyk.cn'
}

function requestInterSuc (config) {
  if (config.url.indexOf('front/personalController/idCard') > -1) {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }
  const userInfo = store.getters.getUser || {}
  // Do something before request is sent
  config.headers.Authorization = userInfo.authorization
  config.headers.userNo = userInfo.userId
  config.headers.region = sessionStorage.getItem('area') || ''
  return config
}

function requestInterFail (error) {
  // Do something with request error
  return Promise.reject(error)
}

function responseInterSuc (value) {
  // 对响应数据做点什么
  return Promise.resolve(value.data)
}

function responseInterFail (error) {
  // 对响应错误做点什么
  if (error.response.data.message.includes('登录超时')) {
    store.commit('logout')
    router.replace('/login')
  }
  return Promise.reject(error)
}

// formdata上传
let formdataConfig = {
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : fetchUrl,
  timeout: 30000,
  withCredentials: false,
  headers: {
    Accept: 'application/json;'
  }
}
export const serviceFormData = axios.create(formdataConfig)
serviceFormData.interceptors.request.use(requestInterSuc, requestInterFail)
serviceFormData.interceptors.response.use(responseInterSuc, responseInterFail)

let baseConfig = {
  baseURL: process.env.NODE_ENV === 'development' ? '/api' : fetchUrl,
  timeout: 30000,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  },
  transformRequest: [function (data) {
    if (data && data.isFormData) {
      delete data.isFormData
      let ret = ''
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret
    } else {
      return JSON.stringify(data)
    }
  }]
}

// 登陆前调用
export const servicePre = axios.create(baseConfig)

// 登陆后调用
const service = axios.create(baseConfig)
service.interceptors.request.use(requestInterSuc, requestInterFail)
service.interceptors.response.use(responseInterSuc, responseInterFail)

export default service
